import {
    URLAPI,
    CITY_PATH,
    CITY_CREATE_PATH,
    CITY_UPDATE_PATH,
    CITY_DELETE_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_city: {
        data: [],
        status: 0,
        message: {},
    },
    _create_city: {
        data: {},
        status: 0,
        message: {},
    },
    _update_city: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_city: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_CITY_SUCCESS = "GET_ALL_CITY_SUCCESS";
export const CREATE_CITY = "CREATE_CITY";
export const UPDATE_CITY = "UPDATE_CITY";
export const DELETE_CITY = "DELETE_CITY";

export const city_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_CITY_SUCCESS:
            return {
                ...state,
                _get_all_city: action.payload,
            };
        case CREATE_CITY:
            return {
                ...state,
                _create_city: action.payload,
            };
        case UPDATE_CITY:
            return {
                ...state,
                _update_city: action.payload,
            };
        case DELETE_CITY:
            return {
                ...state,
                _delete_city: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};


export const get_all_city = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${CITY_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_CITY_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_CITY_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_CITY_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const create_city = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${URLAPI}${CITY_CREATE_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_CITY,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_CITY,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_CITY,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const update_city = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${CITY_UPDATE_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_CITY,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_CITY,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_CITY,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const delete_city = (token, id) => async (dispatch) => {
    try {
        const response = await axios.delete(
            `${URLAPI}${CITY_DELETE_PATH}${id}/`,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_CITY,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: DELETE_CITY,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_CITY,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const clear_city = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
