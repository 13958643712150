import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    CREATE_FAMILY_SUCCESS,
    create_family,
    get_all_family,
    clear_status_family
} from "../../../redux/families";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";
import {ReactComponent as ReactCancel} from '../../../cancel.svg';
import {URLAPI} from "../../../config/index";
import LoadingSpinner from "../../../components/loading-spinner";


const CreateFamily = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const _create_family = useSelector((state) => state.family_module._create_family);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        nombre: "",
        imagen: "",
    });

    const [imagePreview, setImagePreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        setImagePreview(url);
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    useEffect(() => {
        if (_create_family.status === 201) {
            dispatch(clear_status_family(CREATE_FAMILY_SUCCESS));
            history.push("/familias");
            dispatch(get_all_family(token));
            setIsLoading(false);
        } else if (_create_family.status !== 0) {
            dispatch(clear_status_family(CREATE_FAMILY_SUCCESS));
            toast.error(_create_family.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_family, dispatch, history, token]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        //validate fields
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        const data = new FormData();
        data.append('nombre', form.nombre);
        data.append('imagen', form.imagen);
        dispatch(create_family(token, data));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            {isLoading && <LoadingSpinner/>}
            <div><ToastContainer/></div>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Crear"
                            items={[{label: "Familias", link: "/familias"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="col-12 mb-2">
                                    <div className="d-flex">
                                        <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {
                                            history.push(`/familias`);
                                        }}>
                                            <ReactCancel width={32} heght={29}/>
                                        </div>
                                        <p className="type_text text_color text_inter text_32">Crear familia</p>
                                    </div>
                                    <p className="type_text text_black text_inter text_16">Escribe el nombre de la
                                        familia</p>
                                    <form className="card" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input
                                                    label="nombre"
                                                    name="nombre"
                                                    value={form.nombre}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input
                                                    name="imagen"
                                                    label="Imagen"
                                                    type="file"
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                            {form.imagen &&
                                                <div className="col-md-6 text-center">
                                                    <img
                                                        src={
                                                            imagePreview == null
                                                                ? `${URLAPI}${form.imagen}`
                                                                : imagePreview
                                                        }
                                                        alt="imagen"
                                                        width={100}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className="card-footer d-flex justify-content-end">
                                            <Link to="/familias" className="btn btn-outline-dark m-1">
                                                Cancelar
                                            </Link>
                                            <Button type="submit" text="Guardar" theme="calendar" disabled={isLoading}/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CreateFamily;
