import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import LoadingSpinner from '../../../components/loading-spinner';

import {
    clear_manufacturer,
    create_manufacturer,
    CREATE_MANUFACTURER,
    delete_manufacturer,
    DELETE_MANUFACTURER,
    get_all_manufacturer,
    update_manufacturer,
    UPDATE_MANUFACTURER
} from "../../../redux/manufacturers";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Manufacturers = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_manufacturer = useSelector((state) => state.manufacturer_module._get_all_manufacturer);
    const _create_manufacturer = useSelector((state) => state.manufacturer_module._create_manufacturer);
    const _update_manufacturer = useSelector((state) => state.manufacturer_module._update_manufacturer);
    const _delete_manufacturer = useSelector((state) => state.manufacturer_module._delete_manufacturer);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (_get_all_manufacturer.data.datos) {
            setData(_get_all_manufacturer.data.datos);
        }
    }, [_get_all_manufacturer]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal_delete, setModalDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState(0);

    const [form, setForm] = useState({
        identificacion: "",
        nombres: "",
    });

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_manufacturers').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        setModal(true);
                        setId(item.id);
                        setForm({...form, identificacion: item.identificacion, nombres: item.nombres});
                    },
                })

            }
            if (permisos.filter(x => x.codigo === 'delete_manufacturers').length > 0) {
                act.push({
                    name: "delete",
                    label: "Eliminar",
                    icon: "bx bxs-trash",
                    color: "danger",
                    onClick: (item) => {
                        setModalDelete(true);
                        setId(item.id)
                    },
                })

            }
        }
        setActions(act);
        // eslint-disable-next-line
    }, [permisos, history]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (_create_manufacturer.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_manufacturer(CREATE_MANUFACTURER));
            dispatch(get_all_manufacturer(token));
            setIsLoading(false);
            setModal(false);
        } else if (_create_manufacturer.status !== 0) {
            dispatch(clear_manufacturer(CREATE_MANUFACTURER));
            toast.error(_create_manufacturer.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_manufacturer, dispatch, history, token, id]);

    useEffect(() => {
        if (_update_manufacturer.status === 200) {
            toast.success("Actualizado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_manufacturer(UPDATE_MANUFACTURER));
            dispatch(get_all_manufacturer(token));
            setIsLoading(false);
            setModal(false);
            setId(0);
        } else if (_update_manufacturer.status !== 0) {
            dispatch(clear_manufacturer(UPDATE_MANUFACTURER));
            toast.error(_update_manufacturer.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_manufacturer, dispatch, history, token, id]);

    useEffect(() => {
        if (_delete_manufacturer.status === 200) {
            toast.success("Eliminado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_manufacturer(DELETE_MANUFACTURER));
            dispatch(get_all_manufacturer(token));
            setModalDelete(false);
            setId(0);
            setIsLoading(false);
        } else if (_delete_manufacturer.status !== 0) {
            dispatch(clear_manufacturer(DELETE_MANUFACTURER));
            toast.error(_delete_manufacturer.message);
            setIsLoading(false);
        }
    }, [_delete_manufacturer, history, token, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.identificacion === "") {
            toast.error("Debe ingresar una identificación", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.nombres === "") {
            toast.error("Debe ingresar los nombres", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (id === 0) {
            dispatch(create_manufacturer(token, form));
        } else {
            dispatch(update_manufacturer(token, id, form));
        }
    };

    const handleDelete = () => {
        setIsLoading(true);
        dispatch(delete_manufacturer(token, id));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Lista"
                            items={[{label: "Fabricantes", link: "/fabricantes"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_manufacturers').length > 0 ?
                                        <Button type="button" text="Crear" theme="calendar" onClick={() => {
                                            setModal(true);
                                            setForm({...form, identificacion: '', nombres: ''});
                                            setId(0);
                                        }}/> : null
                                }
                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "identificacion",
                                                label: "Identificación",
                                                type: "text",
                                            },
                                            {
                                                name: "nombres",
                                                label: "Nombres",
                                                type: "text",
                                            },
                                            {
                                                name: "fecha_creacion",
                                                label: "Fecha de creación",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleSubmit}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">{id === 0 ? 'Crear ' : 'Editar '} fabricante</h5>
                        <div className="form-group">
                            <Input
                                label="Identificación"
                                name="identificacion"
                                value={form.identificacion}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <Input
                                label="Nombres"
                                name="nombres"
                                value={form.nombres}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button disabled={isLoading} onClick={() => {
                        }} className={`btn btn-calendar mb-1 button_div`}>Guardar
                        </button>
                        <button type="button" onClick={() => {
                            setModal(false);
                        }} className={`btn btn-outline-calendar button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main div-modal" show={modal_delete}>
                <div className="modal-content">
                    <div className="card-body text-center">
                        <h5>¿Está seguro de eliminar o inactivar el registro?</h5>
                    </div>
                    <div className="card-footer text-center">
                        <button type="button" className="btn btn-calendar mb-1 button_div" onClick={() => {
                            handleDelete()
                        }} disabled={isLoading}>
                            SI
                        </button>
                        <button type="button" className="btn btn-outline-calendar button_div" onClick={() => {
                            setModalDelete(false)
                        }}>
                            NO
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Manufacturers;
