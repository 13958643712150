import {
    URLAPI,
    CATALOGUE_ITEM_PATH,
    ITEM_PATH,
    ITEM_CREATE_PATH,
    ITEM_UPDATE_PATH,
    ITEM_IMAGE_DELETE_PATH,
    VALUES_ATTRIBUTES_PATH,
    VALUES_FAMILY_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_items: {
        data: [],
        status: 0,
        message: {},
    },
    _create_item: {
        data: {},
        status: 0,
        message: {},
    },
    _update_item: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_item_image: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_ITEMS = "GET_ALL_ITEMS";
export const CREATE_ITEM = "CREATE_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const DELETE_ITEM_IMAGE = "DELETE_ITEM_IMAGE";

export const item_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_ITEMS:
            return {
                ...state,
                _get_all_items: action.payload,
            };
        case CREATE_ITEM:
            return {
                ...state,
                _create_item: action.payload,
            };
        case UPDATE_ITEM:
            return {
                ...state,
                _update_item: action.payload,
            };
        case DELETE_ITEM_IMAGE:
            return {
                ...state,
                _delete_item_image: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};

export const get_all_items = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${ITEM_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_ITEMS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_ITEMS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_ITEMS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const search_items = async (token, params) => {
    return await axios.get(`${URLAPI}${ITEM_PATH}${params}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const search_catalogue_items = async (token, params) => {
    return await axios.get(`${URLAPI}${CATALOGUE_ITEM_PATH}${params}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const search_values_attributes = async (token, params) => {
    return await axios.get(`${URLAPI}${VALUES_ATTRIBUTES_PATH}${params}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const search_values_family = async (token, params) => {
    return await axios.get(`${URLAPI}${VALUES_FAMILY_PATH}${params}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const create_item = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${URLAPI}${ITEM_CREATE_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_ITEM,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_ITEM,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_ITEM,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const update_item = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${ITEM_UPDATE_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_ITEM,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_ITEM,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_ITEM,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const delete_item_image = (token, id) => async (dispatch) => {
    try {
        const response = await axios.delete(
            `${URLAPI}${ITEM_IMAGE_DELETE_PATH}${id}/`,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_ITEM_IMAGE,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: DELETE_ITEM_IMAGE,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_ITEM_IMAGE,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};


export const clear_item = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};