import React, {useEffect, useState} from "react";
import './footer-client.css';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const FooterClient = () => {
    const history = useHistory();
    const [families, setFamilies] = useState([]);
    const _get_all_family = useSelector(
        (state) => state.family_module._get_all_family
    );
    useEffect(() => {
        if (_get_all_family.data && _get_all_family.data.datos) {
            let values = _get_all_family.data.datos.filter((x) => x.activo === true);
            setFamilies(values);
        }
    // eslint-disable-next-line
    }, [_get_all_family]);
    return (
        <footer>
            <div className="row" style={{background: 'rgb(0 0 0 / 81%)'}}>
                <div className="col-12 text-center p-2 mb-2" style={{background: '#000'}}>
                    <span style={{paddingRight: 10}}><i className="bx bx-envelope"/></span>
                    <strong className="text-color-footer" style={{paddingRight: 20}}> Sucríbete y mantente al día
                        con nuestras Ofertas y promociones</strong>
                    <input style={{height: 36}} type="text" name="suscribete" placeholder="Ingresa tu Email"/>
                    <button className="btn-suscribete-cliente">Suscribirme</button>
                </div>
                <div className="col-lg-3 col-md-12 p-2 text-center">
                    <img
                        className="logo"
                        src="../../../base/app-assets/images/logo/logo_blanco.png"
                        alt="logo"
                        width={150}
                        height={120}
                    />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <h5 className="text-color-footer">SOBRE NOSOTROS</h5>
                    <ul>
                        <li className="text-color-footer">Quienes somos</li>
                        <li className="text-color-footer">Que hacemos</li>
                    </ul>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <h5 className="text-color-footer">SERVICIO AL CLIENTE</h5>
                    <ul>
                        <li className="text-color-footer">Preguntas frecuentes</li>
                        <li className="text-color-footer">Despacho</li>
                        <li className="text-color-footer">Políticas de seguridad</li>
                        <li className="text-color-footer">Términos y condiciones</li>
                        <li className="text-color-footer">Contacto</li>
                        <li className="text-color-footer">Denuncias</li>
                    </ul>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <h5 className="text-color-footer">PRODUCTOS</h5>
                    <ul>
                        {families.map((x) => (
                            <li className="text-color-footer" style={{cursor: "pointer"}} onClick={() => {
                                    history.push(`/familia/${x.nombre}`);
                                }}>{x.nombre}</li>
                        ))}
                    </ul>
                </div>
                {/*<div className="col-lg-2 col-md-3 col-sm-6 col-12">*/}
                {/*    <h5 className="text-color-footer">Cliente</h5>*/}
                {/*    <ul>*/}
                {/*        <li className="text-color-footer">Iniciar sesión</li>*/}
                {/*        <li className="text-color-footer">Registrarme</li>*/}
                {/*        <li className="text-color-footer">Recuperar contraseña</li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
                <div className="col-12 text-center">
                    <div className="p-2" style={{borderTop: '2px #FFF solid', borderBottom: '2px #FFF solid', margin: '1rem 3rem'}}>
                        <span style={{paddingRight: 10}}><i className="bx bx-envelope"/></span>
                        <span className="text-color-footer" style={{paddingRight: 20,}}> Contacto: <strong
                            className="text-color-footer">mayorista@zdpack.cl</strong></span>
                        <strong className="text-color-footer" style={{fontSize: 20, paddingLeft: 30, paddingRight: 30}}>|</strong>
                        <span style={{paddingRight: 10}}><i className="bx bx-phone"/></span>
                        <span className="text-color-footer" style={{paddingRight: 20,}}> Llámanos: <strong
                            className="text-color-footer">+56 600 366 0120</strong></span>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default FooterClient;
