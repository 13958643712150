import React from "react";
import { Link } from "react-router-dom";

/**  @module Components/Breadcrumbs */

/**
 * Toma un título y una matriz de elementos, y devuelve un componente de ruta de navegación
 * @param {string} title - Título de la ruta de navegación
 * @param {array} items - Matriz de elementos de la ruta de navegación
 * @returns Un componente que representa una barra de navegación de migas de pan.
 * @example
 * <Breadcrumbs title="Título de la ruta de navegación" items={[{label:"Elemento 1",link:"/"},{label:"Elemento 2",link:"/"}]}/>
 */

const Breadcrumbs = ({ title, items = [] }) => {
  return (
    <div className="row breadcrumbs-top">
      <div className="col-12">
        <h5 className="content-header-title float-left pr-1 mb-0">{title}</h5>
        <div className="breadcrumb-wrapper col-12">
          <ol className="breadcrumb p-0 mb-0">
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="bx bx-home-alt"></i>
              </Link>
            </li>
            {items.map((item, index) => (
              <li className="breadcrumb-item">
                <Link to={item.link}>{item.label}</Link>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;