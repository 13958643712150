import {
    URLAPI,
    MANUFACTURER_PATH,
    MANUFACTURER_CREATE_PATH,
    MANUFACTURER_UPDATE_PATH,
    MANUFACTURER_DELETE_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_manufacturer: {
        data: [],
        status: 0,
        message: {},
    },
    _create_manufacturer: {
        data: {},
        status: 0,
        message: {},
    },
    _update_manufacturer: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_manufacturer: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_MANUFACTURER_SUCCESS = "GET_ALL_MANUFACTURER_SUCCESS";
export const CREATE_MANUFACTURER = "CREATE_MANUFACTURER";
export const UPDATE_MANUFACTURER = "UPDATE_MANUFACTURER";
export const DELETE_MANUFACTURER = "DELETE_MANUFACTURER";

export const manufacturer_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_MANUFACTURER_SUCCESS:
            return {
                ...state,
                _get_all_manufacturer: action.payload,
            };
        case CREATE_MANUFACTURER:
            return {
                ...state,
                _create_manufacturer: action.payload,
            };
        case UPDATE_MANUFACTURER:
            return {
                ...state,
                _update_manufacturer: action.payload,
            };
        case DELETE_MANUFACTURER:
            return {
                ...state,
                _delete_manufacturer: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};


export const get_all_manufacturer = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${MANUFACTURER_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_MANUFACTURER_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_MANUFACTURER_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_MANUFACTURER_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const create_manufacturer = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${URLAPI}${MANUFACTURER_CREATE_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_MANUFACTURER,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_MANUFACTURER,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_MANUFACTURER,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const update_manufacturer = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${MANUFACTURER_UPDATE_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_MANUFACTURER,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_MANUFACTURER,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_MANUFACTURER,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const delete_manufacturer = (token, id) => async (dispatch) => {
    try {
        const response = await axios.delete(
            `${URLAPI}${MANUFACTURER_DELETE_PATH}${id}/`,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_MANUFACTURER,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: DELETE_MANUFACTURER,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_MANUFACTURER,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const clear_manufacturer = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
