import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    UPDATE_FAMILY_SUCCESS,
    CREATE_FAMILY_ATTRIBUTE_SUCCESS,
    DELETE_FAMILY_ATTRIBUTE_SUCCESS,
    update_family,
    get_all_family,
    clear_status_family,
    create_family_attribute,
    delete_family_attribute
} from "../../../redux/families";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";
import Modal from "../../../components/modal";
import SelectInput from "../../../components/select";
import LoadingSpinner from '../../../components/loading-spinner';
import {ReactComponent as ReactCancel} from '../../../cancel.svg';
import {Switch, Space} from "antd";
import {URLAPI} from "../../../config/index";


const UpdateFamily = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const _get_all_family = useSelector((state) => state.family_module._get_all_family);
    const _update_family = useSelector((state) => state.family_module._update_family);
    const _get_all_attributes = useSelector((state) => state.attribute_module._get_all_attribute);
    const _create_family_attribute = useSelector((state) => state.family_module._create_family_attribute);
    const _delete_family_attribute = useSelector((state) => state.family_module._delete_family_attribute);
    const [isLoading, setIsLoading] = useState(false);

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        nombre: "",
        imagen: "",
        activo: false,
        atributos: [],
    });

    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        setImagePreview(url);
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    useEffect(() => {
        if (_get_all_family.data) {
            const value = _get_all_family.data.datos.filter((x) => x.id === parseInt(id));
            if (value.length > 0) {
                setForm({
                    nombre: value[0].nombre,
                    activo: value[0].activo,
                    atributos: value[0].atributos,
                    imagen: value[0].imagen ? value[0].imagen : "",
                });
            }
        }
    }, [_get_all_family, id]);

    useEffect(() => {
        if (_update_family.status === 200) {
            dispatch(clear_status_family(UPDATE_FAMILY_SUCCESS));
            history.push("/familias");
            dispatch(get_all_family(token));
            setIsLoading(false);
        } else if (_update_family.status !== 0) {
            dispatch(clear_status_family(UPDATE_FAMILY_SUCCESS));
            toast.error(_update_family.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_family, dispatch, history, token]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        //validate fields
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        const data = new FormData();
        data.append('nombre', form.nombre);
        data.append('atributos', form.atributos);
        data.append('activo', form.activo);
        data.append('imagen', form.imagen);
        dispatch(update_family(token, id, data));
    };

    const [permisos, setPermisos] = useState([]);
    const [updateFamily, setUpdateFamily] = useState(false);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    useEffect(() => {
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_families').length > 0) {
                setUpdateFamily(true);
            }
        }
    }, [permisos]);

    const [modal_create, setModalCreate] = useState(false);
    const [modal_select, setModalSelect] = useState(false);

    const [attributes, setAttributes] = useState([]);

    const [attribute, setAttribute] = useState({
        nombre_atributo: "",
        tipo: "",
        id_atributo: 0,
        id_familia: id,
    });

    useEffect(() => {
        if (_get_all_attributes.data.datos) {
            setAttributes(_get_all_attributes.data.datos);
        }
    }, [_get_all_attributes]);

    const handleChangeAttribute = (e) => {
        if (e.target.name === "id_atributo") {
            setAttribute({
                ...attribute,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setAttribute({
                ...attribute,
                [e.target.name]: e.target.value,
            });
        }
    };

    useEffect(() => {
        if (_create_family_attribute.status === 201) {
            dispatch(clear_status_family(CREATE_FAMILY_ATTRIBUTE_SUCCESS));
            history.push(`/familia/editar/${id}`);
            dispatch(get_all_family(token));
            setIsLoading(false);
            setModalCreate(false);
            setModalSelect(false);
        } else if (_create_family_attribute.status !== 0) {
            dispatch(clear_status_family(CREATE_FAMILY_ATTRIBUTE_SUCCESS));
            toast.error(_create_family_attribute.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_family_attribute, dispatch, history, token, id]);

    useEffect(() => {
        if (_delete_family_attribute.status === 200) {
            dispatch(clear_status_family(DELETE_FAMILY_ATTRIBUTE_SUCCESS));
            history.push(`/familia/editar/${id}`);
            dispatch(get_all_family(token));
            setIsLoading(false);
            toast.success("Atributo eliminado correctamente", {position: toast.POSITION.TOP_RIGHT});
        } else if (_delete_family_attribute.status !== 0) {
            dispatch(clear_status_family(DELETE_FAMILY_ATTRIBUTE_SUCCESS));
            toast.error(_delete_family_attribute.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_delete_family_attribute, dispatch, history, token, id]);

    const handleSubmitAttribute = (type) => {
        setIsLoading(true);
        if (type === 'CREAR') {
            if (attribute.nombre === "") {
                toast.error("Debe ingresar el nombre del atributo", {position: toast.POSITION.TOP_RIGHT});
                setIsLoading(false);
                return;
            }
        }
        if (type === 'SELECCIONAR') {
            if (attribute.id_atributo === 0) {
                toast.error("Debe ingresar un atributo", {position: toast.POSITION.TOP_RIGHT});
                setIsLoading(false);
                return;
            }
        }

        dispatch(create_family_attribute(token, attribute));
    };

    function deleteFamilyAttribute(id_atributo) {
        setIsLoading(true);
        dispatch(delete_family_attribute(token, id, id_atributo));
    }

    const handleCheckedChange = (checked) => {
        setForm({
            ...form,
            activo: checked,
        });
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            {isLoading && <LoadingSpinner/>}
            <div><ToastContainer/></div>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title={updateFamily ? 'Actualizar' : 'Ver'}
                            items={[{label: "Familias", link: "/familias"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="col-12 mb-2">
                                    {!updateFamily ? (
                                        <div className="d-flex">
                                            <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {
                                                history.push(`/familias`);
                                            }}>
                                                <ReactCancel width={32} heght={29}/>
                                            </div>
                                            <p className="type_text text_color text_inter text_32">Ver familia</p>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="d-flex">
                                                <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {
                                                    history.push(`/familias`);
                                                }}>
                                                    <ReactCancel width={32} heght={29}/>
                                                </div>
                                                <p className="type_text text_color text_inter text_32">Actualizar
                                                    familia</p>
                                            </div>
                                            <p className="type_text text_black text_inter text_16">Escribe el nombre de
                                                la familia</p>
                                        </>
                                    )}
                                    <form className="card" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input
                                                    label="nombre"
                                                    name="nombre"
                                                    value={form.nombre}
                                                    onChange={handleChange}
                                                    disabled={!updateFamily}
                                                />
                                            </div>
                                            <div className="col-md-6 mt-1">
                                                <Space size={14} direction='horizontal'>
                                                    <strong>Activo</strong>
                                                    <Switch
                                                        checked={form.activo}
                                                        checkedChildren="SI"
                                                        unCheckedChildren="NO"
                                                        onChange={handleCheckedChange}
                                                    />
                                                </Space>
                                            </div>
                                            <div className="col-md-6">
                                                <Input
                                                    name="imagen"
                                                    label="Imagen"
                                                    type="file"
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                            {form.imagen &&
                                                <div className="col-md-6 text-center">
                                                    <img
                                                        src={
                                                            imagePreview == null
                                                                ? `${URLAPI}${form.imagen}`
                                                                : imagePreview
                                                        }
                                                        alt="imagen"
                                                        width={100}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-6 col-12">
                                                <p className="type_text text_black text_inter text_16">Elige los
                                                    atributos que tendrá esta familia</p>
                                            </div>
                                            <div className="col-md-6 col-12 d-flex justify-content-end">
                                                <button type="button" onClick={() => {
                                                    setModalSelect(true);
                                                    setAttribute({
                                                        ...attribute,
                                                        tipo: 'SELECCIONAR',
                                                        id_atributo: 0,
                                                        nombre_atributo: ''
                                                    });
                                                    document.getElementById("id_atributo").selectedIndex = 0;
                                                }} className={`btn btn-calendar mr-2`}>Agregar
                                                </button>
                                                <button type="button" onClick={() => {
                                                    setModalCreate(true);
                                                    setAttribute({
                                                        ...attribute,
                                                        tipo: 'CREAR',
                                                        id_atributo: 0,
                                                        nombre_atributo: ''
                                                    });
                                                }} className={`btn btn-calendar`}>Crear
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            {form.atributos.map((x) => (
                                                <div className="col-md-3 col-sm-6 col-12 d-flex text-center">
                                                    <p className="border_card_1"
                                                       style={{padding: 10, width: '90%'}}>{x.nombre}</p>
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#" onClick={() => {
                                                        deleteFamilyAttribute(x.id);
                                                    }}>
                                                        <img
                                                            className="logo"
                                                            src="../../../base/app-assets/images/icon/delete.png"
                                                            alt="logo"
                                                            width={50}
                                                            height={50}
                                                        />
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="card-footer d-flex justify-content-end">
                                            <Link to="/familias" className="btn btn-outline-dark m-1">
                                                Cancelar
                                            </Link>

                                            {updateFamily && (
                                                <Button type="submit" text="Guardar" theme="calendar"
                                                        disabled={isLoading}/>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <Modal className="modal-main div-modal" show={modal_create}>
                                {isLoading && <LoadingSpinner/>}
                                <div className="card-body">
                                    <h5 className="titulo_modal type_text mb-2">Crear atributo</h5>
                                    <div className="form-group">
                                        <Input
                                            label="Nombre"
                                            name="nombre_atributo"
                                            value={attribute.nombre_atributo}
                                            onChange={handleChangeAttribute}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer text-center">
                                    <button type="button" disabled={isLoading} onClick={() => {
                                        handleSubmitAttribute('CREAR')
                                    }} className={`btn btn-calendar mb-1 button_div`}>Crear
                                    </button>
                                    <button type="button" onClick={() => {
                                        setModalCreate(false);
                                    }} className={`btn btn-outline-calendar button_div`}>Cancelar
                                    </button>
                                </div>
                            </Modal>
                            <Modal className="modal-main div-modal" show={modal_select}>
                                {isLoading && <LoadingSpinner/>}
                                <div className="card-body">
                                    <h5 className="titulo_modal type_text mb-2">Agregar atributo</h5>
                                    <div className="form-group">
                                        <label>Atributo</label>
                                        <SelectInput
                                            label="Atributo"
                                            id="id_atributo"
                                            name="id_atributo"
                                            value={0}
                                            onChange={handleChangeAttribute}
                                            options={attributes ? attributes.map((item) => ({
                                                name: item.nombre,
                                                id: item.id,
                                            })) : []}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer text-center">
                                    <button disabled={isLoading} onClick={() => {
                                        handleSubmitAttribute('SELECCIONAR')
                                    }} className={`btn btn-calendar mb-1 button_div`}>Agregar
                                    </button>
                                    <button type="button" onClick={() => {
                                        setModalSelect(false);
                                    }} className={`btn btn-outline-calendar button_div`}>Cancelar
                                    </button>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateFamily;
