/**
 * It takes a name, an array of options, and an id, and returns a select element with the options
 * @returns A select element with the options passed as props.
 */
import React from "react";

const SelectInput = ({
  name,
  options = [{ name: "Prueba", id: 0 }],
  id,
  onChange,
  select,
}) => {
  return (
    <fieldset className="form-group">
      <select className="form-control" id={id} name={name} onChange={onChange}>
        <option value={0}>Seleccione una opcion</option>
        {options.map((item, index) => {
          return select === item.name ? (
            <option key={index} value={item.id} selected>
              {item.name}
            </option>
          ) : (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          );
        })}
      </select>
    </fieldset>
  );
};
export default SelectInput;
