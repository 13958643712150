import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    clear_item,
    UPDATE_ITEM,
    delete_item_image,
    DELETE_ITEM_IMAGE,
    update_item,
} from "../../../redux/items";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";
import {ReactComponent as ReactCancel} from '../../../cancel.svg';
import {ReactComponent as ReactLogo} from '../../../eclipse.svg';
import {Switch, Space} from "antd";
import SelectInput from "../../../components/select";
import LoadingSpinner from "../../../components/loading-spinner";
import {URLAPI} from "../../../config";
import {
    search_items
} from "../../../redux/items";
import Modal from "../../../components/modal";
import Select from "react-select";

const UpdateItem = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {nombre, id} = useParams();

    const _update_item = useSelector((state) => state.item_module._update_item);
    const _delete_item_image = useSelector((state) => state.item_module._delete_item_image);
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (_update_item.status === 200) {
            dispatch(clear_item(UPDATE_ITEM));
            history.push(`/catalogo/item/${nombre}/${id}`);
            setIsLoading(false);
        } else if (_update_item.status !== 0) {
            dispatch(clear_item(UPDATE_ITEM));
            toast.error(_update_item.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_item, dispatch, history, token, nombre, id]);

    useEffect(() => {
        if (_delete_item_image.status === 200) {
            dispatch(clear_item(DELETE_ITEM_IMAGE));
            getItems();
            setIsLoading(false);
        } else if (_delete_item_image.status !== 0) {
            dispatch(clear_item(DELETE_ITEM_IMAGE));
            toast.error(_delete_item_image.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [_delete_item_image, dispatch, token, nombre, id]);


    const _get_all_family = useSelector(
        (state) => state.family_module._get_all_family
    );

    const [updateVariants, setUpdateVariants] = useState([]);
    const [variants, setVariants] = useState([]);
    const [variantsModal, setVariantsModal] = useState([]);

    /* function add_variants() {
        let values = _get_all_family.data.datos.filter((x) => x.nombre === nombre);
        if (values.length > 0) {
            const v = [];
            // eslint-disable-next-line
            values[0].atributos.map((x) => {
                v.push({[x.nombre]: 0, 'nombre': x.nombre, 'valores': x.valores});
            })
            setVariants([...variants, v]);
        }
    } */

    function add_variants_modal() {
        let values = _get_all_family.data.datos.filter((x) => x.nombre === nombre);
        if (values.length > 0) {
            const v = []
            // eslint-disable-next-line
            values[0].atributos.map((valu) => {
                v.push({[valu.nombre]: [], 'nombre': valu.nombre, 'valores': valu.valores});
            })
            setVariantsModal([...variantsModal, v]);
            setModalVariant(true);
        }
    }

    const [form, setForm] = useState({
        codigo_interno: "",
        codigo_fabricante: "",
        titulo: "",
        descripcion: "",
        id_genero: 0,
        genero: '',
        id_categoria: 0,
        categoria: '',
        id_fabricante: 0,
        fabricante: '',
        activo: false,
        imagenes: [],
        variantes: [],
    });


    useEffect(() => {
        getItems();
        // eslint-disable-next-line
    }, []);

    async function getItems() {
        setIsLoading(true);
        const params = '?id=' + id;
        const response = await search_items(token, params);
        if (response.status === 200) {
            setForm({
                codigo_interno: response.data.datos[0].codigo_interno,
                codigo_fabricante: response.data.datos[0].codigo_fabricante,
                titulo: response.data.datos[0].titulo,
                descripcion: response.data.datos[0].descripcion,
                id_genero: response.data.datos[0].id_genero,
                genero: response.data.datos[0].genero,
                id_categoria: response.data.datos[0].id_categoria,
                categoria: response.data.datos[0].categoria,
                id_fabricante: response.data.datos[0].id_fabricante,
                fabricante: response.data.datos[0].fabricante,
                activo: response.data.datos[0].activo,
                imagenes: response.data.datos[0].imagenes,
                variantes: response.data.datos[0].variantes,
            });
            if (response.data.datos[0].variantes.length > 0) {
                let va = [];
                // eslint-disable-next-line
                response.data.datos[0].variantes.map((x) => {
                    let v = []
                    // eslint-disable-next-line
                    x.valores.map((y) => {
                        v.push({[y.nombre_atributo]: y.id, 'nombre': y.nombre_atributo, 'valores': y.atributo_valores});
                    })
                    va.push(v);
                })
                setUpdateVariants(va);
            }
        }
        setIsLoading(false);
    }

    const [genders, setGenders] = useState([]);

    const _get_all_gender = useSelector((state) => state.gender_module._get_all_gender);

    useEffect(() => {
        if (_get_all_gender.data.datos) {
            let values = _get_all_gender.data.datos.filter((x) => x.activo === true);
            setGenders(values);
        }
    }, [_get_all_gender]);

    const [categories, setCategories] = useState([]);

    const _get_all_category = useSelector((state) => state.category_module._get_all_category);

    useEffect(() => {
        if (_get_all_category.data.datos) {
            let values = _get_all_category.data.datos.filter((x) => x.activo === true && x.familia === nombre);
            setCategories(values);
        }
    }, [_get_all_category, nombre]);

    const [manufacturers, setManufacturers] = useState([]);

    const _get_all_manufacturer = useSelector((state) => state.manufacturer_module._get_all_manufacturer);

    useEffect(() => {
        if (_get_all_manufacturer.data.datos) {
            let values = _get_all_manufacturer.data.datos.filter((x) => x.activo === true);
            setManufacturers(values);
        }
    }, [_get_all_manufacturer]);

    const handleCheckedChange = (checked) => {
        setForm({
            ...form,
            activo: checked,
        });
    };

    const handleChange = (e) => {
        if (e.target.name === "id_genero" || e.target.name === "id_categoria" || e.target.name === "id_fabricante") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleChangeVariant = (e, index, y) => {
        variants[index][y][e.target.name] = parseInt(e.target.value);
    };

    const handleChangeVariantUpdate = (e, index, y) => {
        updateVariants[index][y][e.target.name] = parseInt(e.target.value);

    };

    const [images, setImages] = useState([]);

    const handleImageChange = (e) => {
        setImages([
            ...images,
            e.target.files[0]
        ]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        //validate fields
        if (form.codigo_interno === "") {
            toast.error("Debe ingresar el código interno del producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.codigo_fabricante === "") {
            toast.error("Debe ingresar el código del fabricante del producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.titulo === "") {
            toast.error("Debe ingresar el título del producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.descripcion === "") {
            toast.error("Debe ingresar una descripción del producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.id_genero === 0) {
            toast.error("Debe seleccionar un género para el producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.id_categoria === 0) {
            toast.error("Debe seleccionar una categoría para el producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.id_fabricante === 0) {
            toast.error("Debe seleccionar un fabricante para el producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        if (images.length === 0 && form.imagenes.length === 0) {
            toast.error("Debe seleccionar una imagen para el producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        if (variants.length === 0 && updateVariants.length === 0) {
            toast.error("Debe agregar por lo menos una variante para el producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        } else {
            // eslint-disable-next-line
            variants.map((row) => {
                // eslint-disable-next-line
                row.map((x) => {
                    if (parseInt(x[x.nombre]) === 0) {
                        toast.error("Debe seleccionar un valor a la variante " + x.nombre, {position: toast.POSITION.TOP_RIGHT});
                        setIsLoading(false);
                        // eslint-disable-next-line
                        return;
                    }
                })
            })
            // eslint-disable-next-line
            updateVariants.map((row) => {
                // eslint-disable-next-line
                row.map((x) => {
                    if (parseInt(x[x.nombre]) === 0) {
                        toast.error("Debe seleccionar un valor a la variante " + x.nombre, {position: toast.POSITION.TOP_RIGHT});
                        setIsLoading(false);
                        // eslint-disable-next-line
                        return;
                    }
                })
            })
        }

        let value_variants = []
        // eslint-disable-next-line
        variants.map((row, index) => {
            let attribute_values = [];
            // eslint-disable-next-line
            row.map((x) => {
                attribute_values.push(x[x.nombre]);
            })
            value_variants.push({'variante': index + 1, 'valores_atributo': attribute_values});
        })

        let vari = [];
        let valid = true;
        // eslint-disable-next-line
        value_variants.map((x) => {
            if (JSON.stringify(vari) !== JSON.stringify(x.valores_atributo)) {
                vari = x.valores_atributo
            } else {
                toast.error("Existen variantes repetidas, revise por favor ", {position: toast.POSITION.TOP_RIGHT});
                setIsLoading(false);
                valid = false;
                // eslint-disable-next-line
                return;
            }
        })
        if (valid === true) {
            let update_variants = [];
            // eslint-disable-next-line
            updateVariants.map((row, index) => {
                let update_attribute_values = [];
                // eslint-disable-next-line
                row.map((x) => {
                    update_attribute_values.push(x[x.nombre]);
                })
                update_variants.push({'variante': index + 1, 'valores_atributo': update_attribute_values});
            })

            let vari1 = [];
            let valid1 = true;
            // eslint-disable-next-line
            update_variants.map((x) => {
                if (JSON.stringify(vari1) !== JSON.stringify(x.valores_atributo)) {
                    // eslint-disable-next-line
                    value_variants.map((y) => {
                        if (JSON.stringify(x.valores_atributo) !== JSON.stringify(y.valores_atributo)) {
                            vari1 = x.valores_atributo
                        } else {
                            toast.error("Existen variantes repetidas, revise por favor ", {position: toast.POSITION.TOP_RIGHT});
                            setIsLoading(false);
                            valid1 = false;
                            // eslint-disable-next-line
                            return;
                        }
                    })
                } else {
                    toast.error("Existen variantes repetidas, revise por favor ", {position: toast.POSITION.TOP_RIGHT});
                    setIsLoading(false);
                    valid1 = false;
                    // eslint-disable-next-line
                    return;
                }
            })
            if (valid1 === true) {
                const data = new FormData();
                images.forEach((file, i) => {
                    data.append(`imagenes`, file, file.name);
                });

                data.append('codigo_interno', form.codigo_interno);
                data.append('codigo_fabricante', form.codigo_fabricante);
                data.append('titulo', form.titulo);
                data.append('descripcion', form.descripcion);
                data.append('id_genero', form.id_genero);
                data.append('id_categoria', form.id_categoria);
                data.append('id_fabricante', form.id_fabricante);
                data.append('activo', JSON.stringify(form.activo));
                data.append('variantes', JSON.stringify(value_variants));
                data.append('variantes_actuales', JSON.stringify(update_variants));
                dispatch(update_item(token, id, data));
            }
        }
    };

    function deleteImage(id) {
        setIsLoading(true);
        dispatch(delete_item_image(token, id));
    }

    function arrayProduct(...arrays) {
        return arrays[0].reduce((prevAccumulator, currentArray) => {
            let newAccumulator = [];
            prevAccumulator.forEach(prevAccumulatorArray => {
                currentArray.forEach(currentValue => {
                    newAccumulator.push(prevAccumulatorArray.concat(currentValue));
                });
            });
            return newAccumulator;
        }, [[]]);
    }

    const [modalVariant, setModalVariant] = useState(false);

    const handleSubmitVariations = (e) => {
        e.preventDefault();
        let variantes = [];
        let num = 1;
        // eslint-disable-next-line
        variantsModal.map((row, index) => {
            // eslint-disable-next-line
            row.map((x) => {
                var c = x[x.nombre].length;
                if (x[x.nombre].length > 0) {
                    variantes.push(x[x.nombre]);
                    num = c * num;
                }
            })
        })

        var new_variants = arrayProduct(variantes);

        let valid = true;

        // eslint-disable-next-line
        variants.map((row, index) => {
            let attribute_values = []
            // eslint-disable-next-line
            row.map((x) => {
                attribute_values.push(x[x.nombre]);
            })
            // eslint-disable-next-line
            new_variants.map((d) => {
                if (JSON.stringify(d) === JSON.stringify(attribute_values)) {
                    toast.error("Existen variantes repetidas, revise por favor ", {position: toast.POSITION.TOP_RIGHT});
                    valid = false;
                    // eslint-disable-next-line
                    return;
                }
            });
        });

        let values = _get_all_family.data.datos.filter((x) => x.nombre === nombre);

        if (valid === true) {
            for (var n of new_variants) {
                let v = [];
                for (var m of n) {
                    // eslint-disable-next-line
                    values[0].atributos.map((x) => {
                        // eslint-disable-next-line
                        x.valores.map(q => {
                            if (q.id === m) {
                                v.push({
                                    [x.nombre]: m,
                                    'nombre': x.nombre,
                                    'nombre_atributo': q.nombre,
                                    'valores': x.valores
                                });
                            }
                        })
                    })
                }
                variants.push(v);
            }
            setVariantsModal([]);
            setModalVariant(false);
        }
    };

    const handleChangeSelect = (e, index, y, z) => {
        if (z.action === 'select-option') {
            variantsModal[index][y][z.name].push(z.option.value);
        }
        if (z.action === 'remove-value') {
            const ind = variantsModal[index][y][z.name].indexOf(z.removedValue.value);
            variantsModal[index][y][z.name].splice(ind, 1);

        }
        if (z.action === 'clear') {
            // eslint-disable-next-line
            z.removedValues.map((item) => {
                const ind = variantsModal[index][y][z.name].indexOf(item.value);
                variantsModal[index][y][z.name].splice(ind, 1);
            })
        }
    };


    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Actualizar"
                            items={[
                                // eslint-disable-next-line
                                {label: "Item " + `${nombre}`, link: `/catalogo/item/${nombre}/${id}`},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="ml-1">
                                        <div className="d-flex">
                                            <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {
                                                history.push(`/catalogo/item/${nombre}/${id}`);
                                            }}>
                                                <ReactCancel width={32} heght={29}/>
                                            </div>
                                            <p className="type_text text_color text_inter text_32">{form.titulo !== '' && form.titulo + ' - '}{nombre}</p>
                                        </div>
                                        <p className="type_text text_black text_inter text_16">Agrega las fotos del
                                            producto (mínimo una foto)</p>
                                    </div>
                                    <div className="row border_card_1 m-1">
                                        <div className="p-1">
                                            <div className="card_image" style={{
                                                width: 120,
                                                height: 120,
                                                cursor: 'pointer',
                                                position: "absolute"
                                            }}>
                                                <div className="margin_cicle">
                                                    <ReactLogo width={100} heght={100}/>

                                                </div>
                                                <p className="p_create">+</p>
                                            </div>
                                            <input type="file" onChange={handleImageChange} multiple
                                                   style={{opacity: 0, width: 120, height: 120}}/>
                                        </div>
                                        {(form.imagenes) && (
                                            form.imagenes.map((x) => (
                                                <div className="m-1 d-flex">
                                                    <img
                                                        src={URLAPI + x.url}
                                                        alt='Imagen'
                                                        width={120}
                                                        height={120}
                                                    />
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#" onClick={() => {
                                                        deleteImage(x.id);
                                                    }}>
                                                        <img
                                                            alt="logo"
                                                            src="../../../../base/app-assets/images/icon/delete.png"
                                                            width={30}
                                                            height={30}
                                                        />
                                                    </a>
                                                </div>
                                            ))
                                        )}
                                        {(images) && (
                                            // eslint-disable-next-line
                                            images.map((_) => (
                                                <div className="m-1 d-flex">
                                                    <img
                                                        src={URL.createObjectURL(_)}
                                                        alt={_.name}
                                                        width={120}
                                                        height={120}
                                                    />
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#" onClick={() => {
                                                        const index = images.indexOf(_);
                                                        images.splice(index, 1);
                                                    }}>
                                                        <img
                                                            className="logo"
                                                            src="../../../../base/app-assets/images/icon/delete.png"
                                                            alt="Eliminar"
                                                            width={30}
                                                            height={30}
                                                        />
                                                    </a>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                    <div className="row mt-2" style={{margin: '0 8px'}}>
                                        <div className="col-md-9 col-sm-6 col-12">
                                            <p className="type_text text_black text_inter text_16">Agrega la información
                                                general del producto</p>
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-12 text-right">
                                            <Space size={16} direction='horizontal'>
                                                <strong>Activo</strong>
                                                <Switch
                                                    checked={form.activo}
                                                    checkedChildren="SI"
                                                    unCheckedChildren="NO"
                                                    onChange={handleCheckedChange}
                                                />
                                            </Space>
                                        </div>
                                    </div>
                                    <div className="row border_card_1 m-1">
                                        <div className="col-md-4 col-12">
                                            <Input
                                                label="Código interno"
                                                name="codigo_interno"
                                                value={form.codigo_interno}
                                                onChange={handleChange}
                                                color_label="texto2"
                                            />
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <Input
                                                label="Código del fabricante"
                                                name="codigo_fabricante"
                                                value={form.codigo_fabricante}
                                                onChange={handleChange}
                                                color_label="texto2"
                                            />
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <Input
                                                label="Título"
                                                name="titulo"
                                                value={form.titulo}
                                                onChange={handleChange}
                                                color_label="texto2"
                                            />
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <Input
                                                label="Descripcion"
                                                name="descripcion"
                                                value={form.descripcion}
                                                onChange={handleChange}
                                                color_label="texto2"
                                            />
                                        </div>
                                        <div className="col-md-4 col-16">
                                            <div className="form-group">
                                                <small className="texto2">Género</small>
                                                <SelectInput
                                                    label="Género"
                                                    name="id_genero"
                                                    value={form.id_genero}
                                                    select={form.genero}
                                                    onChange={handleChange}
                                                    options={genders ? genders.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-16">
                                            <div className="form-group">
                                                <small className="texto2">Categoría</small>
                                                <SelectInput
                                                    label="Categoría"
                                                    name="id_categoria"
                                                    value={form.id_categoria}
                                                    select={form.categoria}
                                                    onChange={handleChange}
                                                    options={categories ? categories.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-16">
                                            <div className="form-group">
                                                <small className="texto2">Fabricante</small>
                                                <SelectInput
                                                    label="Fabricante"
                                                    name="id_fabricante"
                                                    value={form.id_fabricante}
                                                    select={form.fabricante}
                                                    onChange={handleChange}
                                                    options={manufacturers ? manufacturers.map((item) => ({
                                                        name: item.nombres,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2" style={{margin: '0 8px'}}>
                                        <div className="col-md-9 col-sm-6 col-12">
                                            <p className="type_text text_black text_inter text_16">Agrega las variantes
                                                del producto. Las variantes son las diferentes medidas y pesos
                                                disponibles</p>
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-12 text-right">
                                            {/* <button type="button" className="btn btn-calendar" onClick={() => {add_variants()}}>Agregar</button> */}
                                            <button type="button" className="btn btn-calendar mt-1" onClick={() => {
                                                add_variants_modal();
                                            }}>Agregar
                                            </button>
                                        </div>
                                    </div>
                                    {form.variantes && (
                                        form.variantes.map((row, index) => (
                                            <div className="row border_card_1 m-1">
                                                <div className="col-md-12 col-12 mt-1">
                                                    <p className="type_text text_black text_inter text_16">Variante {index + 1}</p>
                                                </div>
                                                {row.valores.map((x, y) => (
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <small className="texto2">{x.nombre_atributo}</small>
                                                            <SelectInput
                                                                label={x.nombre_atributo}
                                                                name={x.nombre_atributo}
                                                                select={x.nombre}
                                                                onChange={(e) => {
                                                                    handleChangeVariantUpdate(e, index, y)
                                                                }}
                                                                options={x.atributo_valores ? x.atributo_valores.map((item) => ({
                                                                    name: item.nombre,
                                                                    id: item.id,
                                                                })) : []}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    )}
                                    {variants && (
                                        variants.map((row, index) => (
                                            <div className="row border_card_1 m-1">
                                                <div className="col-md-8 col-12 mt-1">
                                                    <p className="type_text text_black text_inter text_16">Variante {form.variantes.length + (index + 1)}</p>
                                                </div>
                                                <div className="col-md-4 col-12 mt-1 text-right">
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#" onClick={() => {
                                                        const ind = variants.indexOf(row);
                                                        variants.splice(ind, 1);
                                                        setVariants([...variants]);
                                                    }}>
                                                        <img
                                                            className="logo"
                                                            src="../../../../base/app-assets/images/icon/delete.png"
                                                            alt="Eliminar"
                                                            width={30}
                                                            height={30}
                                                        />
                                                    </a>
                                                </div>
                                                {row.map((x, y) => (
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <small className="texto2">{x.nombre}</small>
                                                            <SelectInput
                                                                label={x.nombre}
                                                                name={x.nombre}
                                                                select={x.nombre_atributo}
                                                                onChange={(e) => {
                                                                    handleChangeVariant(e, index, y)
                                                                }}
                                                                options={x.valores ? x.valores.map((item) => ({
                                                                    name: item.nombre,
                                                                    id: item.id,
                                                                })) : []}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    )}
                                </div>
                                {/* <button type="button" onClick={() => {console.log(variants)}}>Imprimir</button> */}
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to={`/catalogo/item/${nombre}/${id}`} className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="calendar" disabled={isLoading}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modalVariant}>
                <form onSubmit={handleSubmitVariations}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Agregar variante</h5>
                        {variantsModal && (
                            variantsModal.map((row, w) => (
                                row.map((x, y) => (
                                        <div className="form-group">
                                            <label>{x.nombre}</label>
                                            <Select
                                                options={x.valores}
                                                name={x.nombre}
                                                isMulti={true}
                                                onChange={(e, z) => {
                                                    handleChangeSelect(e, w, y, z)
                                                }}
                                            />
                                        </div>
                                    )
                                )
                            )))}

                    </div>
                    <div className="card-footer text-center">
                        <button type="sumit" disabled={isLoading} className={`btn btn-calendar mb-1 button_div`}>Agregar</button>
                        <button type="button" onClick={() => {
                            setModalVariant(false);
                            setVariantsModal([]);
                        }} className={`btn btn-outline-calendar button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Footer/>
        </div>
    );
};

export default UpdateItem;
