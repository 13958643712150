import React from "react";
/**  @module Components/CheckBox */

/**
 * Es una función que toma una etiqueta, un nombre, una identificación, un cambio y un estado, y
 * devuelve un conjunto de campos con un div con una entrada de casilla de verificación y una etiqueta.
 * @param {string} label - El texto que se mostrará en el campo.
 * @param {string} name - El nombre del campo.
 * @param {string} id - La identificación del campo.
 * @param {function} onChange - La función que se ejecuta al cambiar el valor del campo.
 * @param {boolean} checked - El valor del campo.
 * @returns Una casilla de verificación con una etiqueta.
 * @example
 * <CheckBox
 *      label="Texto del campo"
 *      name="nombre"
 *      id="identificacion"
 *      onChange={()=>{}}
 *      checked={false}
 *      />
 */
const CheckBox = ({ label, name, id, onChange, state = false }) => {
  return (
    <fieldset className="mt-2">
      <div className="checkbox checkbox-success">
        <input type="checkbox" className="checkbox-input" id={id} name={name} onChange={onChange} checked={state} />
        <label for={`${id}`}>{label}</label>
      </div>
    </fieldset>
  );
};
export default CheckBox;
