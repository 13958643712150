import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import LoadingSpinner from "../../../components/loading-spinner";
import Search from "../../../components/input";
import {get_all_quotation} from "../../../redux/quotation";
import FooterClient from "../../../components/footer_client";

const MyQuotes = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const _get_all_quotation = useSelector((state) => state.quotation_module._get_all_quotation);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [isLoading, setIsLoading] = useState(false);
    const [datas, setDatas] = useState([]);
    const [n_page, setNPage] = useState(0);

    useEffect(() => {
        setIsLoading(true);
        if (_get_all_quotation.data && _get_all_quotation.data.datos) {
            setDatas(_get_all_quotation.data.datos);
            setNPage(_get_all_quotation.data.n_page);
            setIsLoading(false);
        }
    }, [_get_all_quotation, isLoading]);

    const handleSearch = (e) => {
        if (e.target.value === "") {
            setDatas(_get_all_quotation.data.datos);
            return;
        }
        setDatas([]);
        let value = e.target.value;
        value = value.trim();
        let re = new RegExp(value, "i");
        // eslint-disable-next-line
        let filtro = _get_all_quotation.data.datos.filter(element => {
            if ((element.codigo.match(re)) || (element.estado.match(re))) {
                return element;
            }
        })
        setDatas(filtro);
    }

    const [page, setPage] = useState(1);

    function more_data() {
        let count = page + 1;
        setPage(count)
        dispatch(get_all_quotation(token, count));
    }

    function less_data() {
        let count = page - 1;
        setPage(count)
        dispatch(get_all_quotation(token, count));
    }

    return (
        <div className="color box_shadow">
            <div className="p-1">
                <div className="card-body">
                    {isLoading && <LoadingSpinner/>}
                    <p className="type_text text_20 text_black">Mis cotizaciones</p>
                    <p className="type_text text_16 text_black">Aquí encontrarás el listado de cotizaciones que hayas
                        realizado con nosotros</p>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-12">
                            <Search placeholder={"Buscar"}
                                    onChange={handleSearch}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {(datas.length > 0) && datas.map((x) => (
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="border_card_1 p-1">
                                    <p className="type_text text_24 text_color">{x.codigo}</p>
                                    <p className="type_text text_16 text_color">Estado: {x.estado}</p>
                                    <p className="type_text text_16 text_color">Fecha: {x.fecha_creacion}</p>
                                </div>
                                <>
                                    {/*eslint-disable-next-line */}
                                    <a className="btn-view-cliente" href="#" onClick={() => {
                                        history.push(`/mi-cotizacion/${x.id}`);
                                    }}>Ver</a>
                                </>
                            </div>
                        ))}
                    </div>
                    {(n_page > 1) &&
                        <div className="row" style={{marginTop: 10, marginBottom: 5}}>
                            <div className="col-4">
                                <label>Página {page}</label>
                            </div>
                            <div className="col-8 text-right">
                                {(page > 1 && page <= n_page) &&
                                    <button className="btn btn-calendar-cliente1 mr-1" type="button" onClick={() => {
                                        less_data();
                                    }}><i className="bx bx-arrow-back"></i></button>
                                }
                                {(page >= 1 && page < n_page) &&
                                    <button className="btn btn-calendar-cliente1" type="button" onClick={() => {
                                        more_data();
                                    }}><i className="bx bx-right-arrow-alt"></i></button>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            <FooterClient/>
        </div>
    );
};

export default MyQuotes;
