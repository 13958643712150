/**
 * It returns a fieldset with a label and a textarea
 * @returns A textarea element with a label, id, onChange, name, rows, and placeholder.
 */
import React from "react";

const TextArea = ({ label, name, id, onChange, value, rows, placeholder }) => {
  return (
    <fieldset className="form-group">
      <label for={id}>{label}</label>
      <textarea
        className="form-control"
        id={id}
        onChange={onChange}
        name={name}
        rows={rows}
        placeholder={placeholder}
        value={value}
      >
      </textarea>
    </fieldset>
  );
};

export default TextArea;