import axios from "axios";
import {
    URLAPI,
    ORDER_PATH,
    ORDER_ADMIN_PATH,
    ORDER_PAYMENT_PATH,
    ORDER_UPDATE_PATH,
    ORDER_REPORT_PATH,
    ORDER_REPORT_CHECK_PATH
} from "../config";
import {LOGOUT} from './users'

const init = {
    _get_all_order: {
        data: [],
        status: 0,
        message: {},
    },
    _get_all_order_admin: {
        data: [],
        status: 0,
        message: {},
    },
    _payment_order: {
        data: {},
        status: 0,
        message: {},
    },
    _update_order: {
        data: {},
        status: 0,
        message: {},
    },
    _get_report_order: {
        data: {},
        status: 0,
        message: ""
    },
    _get_notification: {
        data: [],
    },
}

const GET_ALL_ORDER_SUCCESS = "GET_ALL_ORDER_SUCCESS";
const GET_ALL_ORDER_ADMIN_SUCCESS = "GET_ALL_ORDER_ADMIN_SUCCESS";
export const PAYMENT_ORDER_SUCCESS = "PAYMENT_ORDER_SUCCESS";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const GET_REPORT_ORDER = "GET_REPORT_ORDER";
export const GET_NOTIFICATION = "GET_NOTIFICATION";

export const order_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_ORDER_SUCCESS:
            return {
                ...state,
                _get_all_order: action.payload,
            };
        case GET_ALL_ORDER_ADMIN_SUCCESS:
            return {
                ...state,
                _get_all_order_admin: action.payload,
            };
        case PAYMENT_ORDER_SUCCESS:
            return {
                ...state,
                _payment_order: action.payload,
            };
        case UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                _update_order: action.payload,
            };
        case GET_REPORT_ORDER:
            return {
                ...state,
                _get_report_order: action.payload
            };
        case GET_NOTIFICATION:
            return {
                ...state,
                _get_notification: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const get_all_order = (token, page) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${ORDER_PATH}?page=${page}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_ORDER_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_ORDER_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_ORDER_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const get_all_order_admin = (token, page) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${ORDER_ADMIN_PATH}?page=${page}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_ORDER_ADMIN_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_ORDER_ADMIN_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_ORDER_ADMIN_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const payment_order = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${ORDER_PAYMENT_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: PAYMENT_ORDER_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: PAYMENT_ORDER_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: PAYMENT_ORDER_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const update_order = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${ORDER_UPDATE_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: UPDATE_ORDER_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: UPDATE_ORDER_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_ORDER_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const get_report_order = (data, token) => async (dispatch) => {
    try {
        const res = await axios.post(URLAPI + ORDER_REPORT_PATH, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        })

        dispatch({
            type: GET_REPORT_ORDER,
            payload: {
                data: res.data,
                status: res.status,
                message: ""
            }
        })
    } catch (e) {
        dispatch({
            type: GET_REPORT_ORDER,
            payload: {
                data: {},
                status: 500,
                message: "Ocurrio un error al generar el reporte"
            }
        })
    }
}

export const save_notification_order = (data) => async (dispatch) => {

    dispatch({
        type: GET_NOTIFICATION,
        payload: {
            data: data,
        }
    });
}

export const check_export_status_order = async (token, task_id) => {
    return await axios.get(`${URLAPI}${ORDER_REPORT_CHECK_PATH}${task_id}/`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    })
}

export const clear_status_order = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}
