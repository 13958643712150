import {
    URLAPI,
    BANK_ACCOUNT_PATH,
    BANK_ACCOUNT_CREATE_PATH,
    BANK_ACCOUNT_UPDATE_PATH,
    BANK_ACCOUNT_DELETE_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_bank_account: {
        data: [],
        status: 0,
        message: {},
    },
    _create_bank_account: {
        data: {},
        status: 0,
        message: {},
    },
    _update_bank_account: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_bank_account: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_BANK_ACCOUNT_SUCCESS = "GET_ALL_BANK_ACCOUNT_SUCCESS";
export const CREATE_BANK_ACCOUNT = "CREATE_BANK_ACCOUNT";
export const UPDATE_BANK_ACCOUNT = "UPDATE_BANK_ACCOUNT";
export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";

export const bank_account_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_BANK_ACCOUNT_SUCCESS:
            return {
                ...state,
                _get_all_bank_account: action.payload,
            };
        case CREATE_BANK_ACCOUNT:
            return {
                ...state,
                _create_bank_account: action.payload,
            };
        case UPDATE_BANK_ACCOUNT:
            return {
                ...state,
                _update_bank_account: action.payload,
            };
        case DELETE_BANK_ACCOUNT:
            return {
                ...state,
                _delete_bank_account: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};


export const get_all_bank_account = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${BANK_ACCOUNT_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_BANK_ACCOUNT_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_BANK_ACCOUNT_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_BANK_ACCOUNT_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const create_bank_account = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${URLAPI}${BANK_ACCOUNT_CREATE_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_BANK_ACCOUNT,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_BANK_ACCOUNT,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_BANK_ACCOUNT,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const update_bank_account = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${BANK_ACCOUNT_UPDATE_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_BANK_ACCOUNT,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_BANK_ACCOUNT,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_BANK_ACCOUNT,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const delete_bank_account = (token, id) => async (dispatch) => {
    try {
        const response = await axios.delete(
            `${URLAPI}${BANK_ACCOUNT_DELETE_PATH}${id}/`,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_BANK_ACCOUNT,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: DELETE_BANK_ACCOUNT,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_BANK_ACCOUNT,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const clear_bank_account = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
