import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import Footer from "../../../components/footer";
import Button from "../../../components/button";
import LoadingSpinner from "../../../components/loading-spinner";
import InfiniteScroll from "react-infinite-scroller";
import Search from "../../../components/search";
import SelectInput from "../../../components/select";
import {get_all_quotation_admin, get_report, GET_REPORT, clear_status_quotation} from "../../../redux/quotation";
import Input from "../../../components/input";
import Modal from "../../../components/modal"
import { toast, ToastContainer } from "react-toastify";


const Quotations = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const _get_all_quotation_admin = useSelector((state) => state.quotation_module._get_all_quotation_admin);
    const _get_all_states = useSelector((state) => state.states_module._get_all_states);

    const [showSibarLeft, setShowSibarLeft] = useState(false);
    // const [report, setReport] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [datas, setDatas] = useState([]);
    const [states, setStates] = useState([]);
    const [page, setPage] = useState(1);
    const [n_page, setNPage] = useState(0);

    const _get_report = useSelector((store) => store.quotation_module._get_report);

    // useEffect(() => {
    //     setReport(_get_report.status);
    // }, [_get_report]);

    const handleSearch = (e) => {
        setIsLoading(true);
        if (e.target.value === "") {
            setDatas(_get_all_quotation_admin.data.datos);
            setIsLoading(false);
            return;
        }
        setDatas([]);
        let nombre = e.target.value;
        nombre = nombre.trim();
        let re = new RegExp(nombre, "i");
        // eslint-disable-next-line
        let filtro = _get_all_quotation_admin.data.datos.filter(element => {
            if ((element.codigo.match(re) != null) || (element.ciudad.match(re) != null)) {
                return element;
            }
        })
        setDatas(filtro);
        setIsLoading(false);
        setRangeDate({
            date_end: '',
            date_start: '',
            visible: false,
            visible_state: false
        });
        document.getElementById("filtro_cotizacion").selectedIndex = 0;
    }

    const [count, setCount] = useState(0);
    const [HasMore, setHasMore] = useState(true);

    const loadFunc = () => {
        if (_get_all_quotation_admin.data.datos) {
            if (_get_all_quotation_admin.data.datos.length > count) {
                const ordenado = _get_all_quotation_admin.data.datos;
                setDatas([...datas, ...ordenado.slice(count, count + 10)]);
                setCount(count + 10);
            }
            setHasMore(false);
        }
    };

    useEffect(() => {
        if (_get_all_quotation_admin.data && _get_all_quotation_admin.data.datos) {
            setDatas(_get_all_quotation_admin.data.datos);
            setNPage(_get_all_quotation_admin.data.n_page);
        }
    }, [_get_all_quotation_admin]);

    useEffect(() => {
        if (_get_all_states.data && _get_all_states.data.datos) {
            // eslint-disable-next-line
            let filter = _get_all_states.data.datos.filter(x => {
                if (x.descripcion === 'Caducado' || x.descripcion === 'En espera' || x.descripcion === 'Cotizado' || x.descripcion === 'Orden generada' || x.descripcion === 'Pendiente pago' || x.descripcion === 'Revisión pago') {
                    return x
                }
            })
            setStates(filter);
        }
    }, [_get_all_states]);

    // const handleGenerateReport = () => {
    //     let final = [];
    //     datas.forEach(e => {
    //         final.push(e.id);
    //     });

    //     dispatch(get_report({'lista_cotizaciones': final}, token));
    // }

    const filter_options = [
        {name: 'Estados', id: 1},
        {name: 'Rango de fechas', id: 2}
    ];

    const filterToday = (e) => {
        if (e.target.value === '1') {
            setRangeDate({
                date_end: '',
                date_start: '',
                visible: false,
                visible_state: true
            });
        } else if (e.target.value === '2') {
            setRangeDate({
                date_end: '',
                date_start: '',
                visible: true,
                visible_state: false
            });
        } else {
            setRangeDate({
                date_end: '',
                date_start: '',
                visible: false,
                visible_state: false
            });
            setDatas(_get_all_quotation_admin.data.datos);
        }
    }

    const [range_date, setRangeDate] = useState({
        visible: false,
        visible_state: false,
        date_start: '',
        date_end: ''
    })

    const handleFilterRangeDate = (e) => {
        if (e.target.name === 'date_start') {
            range_date.date_start = e.target.value
        }
        if (e.target.name === 'date_end') {
            range_date.date_end = e.target.value
        }

        let actual = _get_all_quotation_admin.data.datos;


        if (range_date.date_start !== '' && range_date.date_end !== '') {
            let date_start = new Date(range_date.date_start + ' 00:00:00');
            let date_end = new Date(range_date.date_end + ' 23:59:59');
            // eslint-disable-next-line
            let final = actual.filter(x => {
                    if (x.fecha_creacion) {
                        let fecha = new Date(x.fecha_creacion);
                        if (fecha.getTime() >= date_start.getTime() && fecha.getTime() <= date_end.getTime()) {
                            return x;
                        }
                    }
                }
            )
            setDatas(final);
        }
    }

    const handleChange = (e) => {
        if (e.target.value !== 0) {
            let actual = _get_all_quotation_admin.data.datos;
            let final = actual.filter(x => x.estado === e.target.value);
            setDatas(final);
        }
    };

    function more_data() {
        let count = page + 1;
        setPage(count)
        dispatch(get_all_quotation_admin(token, count));
    }

    function less_data() {
        let count = page - 1;
        setPage(count)
        dispatch(get_all_quotation_admin(token, count));
    }

    const [fecha_inicial, setFechaInicial] = useState('');
    const [fecha_final, setFechaFinal] = useState('');
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (_get_report.status === 200) {
            toast.success(_get_report.data.mensaje);
            dispatch(clear_status_quotation(GET_REPORT));
            setModal(false);
            setIsLoading(false);
        } else if (_get_report.status !== 0) {
            toast.error(_get_report.message);
            dispatch(clear_status_quotation(GET_REPORT));
            setModal(false);
            setIsLoading(false);
        }
      }, [_get_report, dispatch]);

      const handleGenerateReport = (e) => {
          e.preventDefault();
          setIsLoading(true);
          if (fecha_inicial === "") {
              toast.error("Debe seleccionar la fecha inicial");
              return;
          }
          if (fecha_final === "") {
              toast.error("Debe seleccionar la fecha final");
              return;
          }
          // setReport(false);
          setModal(false);
          dispatch(get_report({'fecha_inicio': fecha_inicial, 'fecha_fin': fecha_final}, token))
      }

    return (
        <div className="app-content content">
            <ToastContainer />
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Cotizaciones"
                            items={[{label: "Lista", link: "/cotizaciones"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="content-area-wrapper" style={{margin: 0}}>
                        <div className={`sidebar-left ${showSibarLeft ? 'show' : ''}`}>
                            <div className="sidebar">
                                <div className="sidebar-content email-app-sidebar d-flex">
                                    <span className="sidebar-close-icon" onClick={
                                        () => {
                                            setShowSibarLeft(false)
                                        }
                                    }>
                                        <i className="bx bx-x"/>
                                    </span>
                                    <div className="email-app-menu">
                                        <div className="form-group form-group-compose">

                                        </div>
                                        <div className="sidebar-menu-list">
                                            <div className="list-group list-group-messages">
                                                <SelectInput
                                                    id="filtro_cotizacion"
                                                    name="filtro-cotizacion"
                                                    options={filter_options}
                                                    onChange={filterToday}
                                                />
                                                <ul className="list-unstyled mb-0">
                                                    {
                                                        range_date.visible ? <>
                                                            <li className=" mr-2 mb-1">
                                                                <Input
                                                                    type="date"
                                                                    label="Fecha Inicio"
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_start'

                                                                />
                                                                <Input
                                                                    type="date"
                                                                    label="Fecha Fin"
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_end'

                                                                />
                                                            </li>
                                                        </> : null
                                                    }
                                                    {
                                                        range_date.visible_state ? <>
                                                            <li className=" mr-2 mb-1">
                                                                <label>Estado</label>
                                                                <SelectInput
                                                                    id="estado"
                                                                    name="estado"
                                                                    onChange={handleChange}
                                                                    options={states ? states.map((item) => ({
                                                                        name: item.descripcion,
                                                                        id: item.descripcion,
                                                                    })) : []}
                                                                />
                                                            </li>
                                                        </> : null
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-right">
                            <div className="content-overlay"/>
                            <div className="content-wrapper">
                                <div className="content-header row"/>
                                <div className="content-body">
                                    <div className="email-app-area">
                                        <div className="email-app-list-wrapper">
                                            <div className="email-app-list">
                                                <div className="email-action">
                                                    <div
                                                        className="action-right d-flex flex-grow-1 align-items-center justify-content-around">
                                                        <div className="email-fixed-search flex-grow-1">
                                                            <div className="sidebar-toggle d-block d-lg-none" onClick={
                                                                () => {
                                                                    setShowSibarLeft(!showSibarLeft);
                                                                }
                                                            }>
                                                                <i className="bx bx-menu"/>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-10 col-sm-8 col-12">
                                                                    <Search placeholder={"Número de cotización"}
                                                                            onChange={handleSearch}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2 col-sm-4 col-12 text-right">
                                                                    <Button
                                                                        text={<h3><i className="text-white bx bx-export"/></h3>}
                                                                        theme="calendar"
                                                                        type="button"
                                                                        classes="btn-lg round"
                                                                        onClick={() => {setModal(true); setFechaInicial(''); setFechaFinal('');}}
                                                                    />
                                                                    {/* {!report ? <Button text="Exportar" theme="calendar"
                                                                                       type="button"
                                                                                       classes="btn-lg"
                                                                                       onClick={() => handleGenerateReport()}
                                                                    /> : <div className="spinner-border text-success"
                                                                              role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="email-user-list list-group">
                                                    <ul className="users-list-wrapper media-list" style={{
                                                        overflowY: 'scroll',
                                                    }}>
                                                        <InfiniteScroll
                                                            pageStart={0}
                                                            loadMore={() => loadFunc()}
                                                            hasMore={HasMore}
                                                            useWindow={false}

                                                            loader={
                                                                <div className="col-12 d-flex justify-content-center">
                                                                    <div
                                                                        className="spinner-border text-success"
                                                                        role="status"
                                                                    >
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                    {" "}
                                                                </div>
                                                            }
                                                        >
                                                            <table className="table table-striped" id="table">
                                                                <thead>
                                                                <tr className="text-center">
                                                                    <th>No. cotización</th>
                                                                    <th>Fecha de creación</th>
                                                                    <th>Fecha de cotizado</th>
                                                                    <th>Estado</th>
                                                                    <th>Ciudad</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {datas.map((item) => (
                                                                    <tr onClick={() => {
                                                                        history.push(`/cotizacion/${item.id}`);
                                                                    }} style={{cursor: 'pointer'}}>
                                                                        <td>{item.codigo}</td>
                                                                        <td>{item.fecha_creacion}</td>
                                                                        <td>{item.fecha_cotizacion}</td>
                                                                        <td>{item.estado}</td>
                                                                        <td>{item.ciudad}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                            {(n_page > 1) &&
                                                                <div className="row ml-1 mr-1"
                                                                     style={{marginTop: 10, marginBottom: 5}}>
                                                                    <div className="col-4">
                                                                        <label>Página {page}</label>
                                                                    </div>
                                                                    <div className="col-8 text-right">
                                                                        {(page > 1 && page <= n_page) &&
                                                                            <button
                                                                                className="btn btn-calendar mr-1"
                                                                                type="button" onClick={() => {
                                                                                less_data();
                                                                            }}><i className="bx bx-arrow-back"></i>
                                                                            </button>
                                                                        }
                                                                        {(page >= 1 && page < n_page) &&
                                                                            <button className="btn btn-calendar"
                                                                                    type="button" onClick={() => {
                                                                                more_data();
                                                                            }}><i className="bx bx-right-arrow-alt"></i>
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </InfiniteScroll>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleGenerateReport}>
                    <div className="card-body">
                    <h5 className="mb-2">Exportar datos</h5>
                        <div className="form-group">
                            <Input
                                type="date"
                                label="Fecha Inicio"
                                onChange={(e) => {setFechaInicial(e.target.value)}}
                                name='fecha_inicial'
                                value={fecha_inicial}
                            />
                            <Input
                                type="date"
                                label="Fecha Fin"
                                onChange={(e) => {setFechaFinal(e.target.value)}}
                                name='fecha_final'
                                value={fecha_final}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button className={`btn btn-calendar mb-1 button_div`}>Exportar</button>
                        <button type="button" onClick={() => {
                            setFechaInicial('');
                            setFechaFinal('');
                            setModal(false);
                            }} className={`btn btn-outline-calendar button_div`}>Cancelar</button>
                    </div>
                </form>
            </Modal>
            <Footer/>
        </div>
    );
};

export default Quotations;
