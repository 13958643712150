import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import LoadingSpinner from '../../../components/loading-spinner';
import {Switch, Space} from "antd";
import {
    clear_type_payment,
    create_type_payment,
    CREATE_TYPE_PAYMENT,
    delete_type_payment,
    DELETE_TYPE_PAYMENT,
    get_all_type_payment,
    update_type_payment,
    UPDATE_TYPE_PAYMENT
} from "../../../redux/type_payment";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {URLAPI} from "../../../config/index";

const TypePayment = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_type_payment = useSelector((state) => state.type_payment_module._get_all_type_payment);
    const _create_type_payment = useSelector((state) => state.type_payment_module._create_type_payment);
    const _update_type_payment = useSelector((state) => state.type_payment_module._update_type_payment);
    const _delete_type_payment = useSelector((state) => state.type_payment_module._delete_type_payment);
    const [data, setData] = useState([]);
    const [imagePreview, setImagePreview] = useState(null);

    useEffect(() => {
        if (_get_all_type_payment.data.datos) {
            setData(_get_all_type_payment.data.datos);
        }
    }, [_get_all_type_payment]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal_delete, setModalDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState(0);

    const [form, setForm] = useState({
        nombre: "",
        pasarela: false,
        activo: true,
        imagen: "",
    });

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_typepayment').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        setModal(true);
                        setId(item.id);
                        console.log(item)
                        setForm({...form, nombre: item.nombre, pasarela: item.pasarela, imagen: item.imagen});
                    },
                })

            }
            if (permisos.filter(x => x.codigo === 'delete_typepayment').length > 0) {
                act.push({
                    name: "delete",
                    label: "Eliminar",
                    icon: "bx bxs-trash",
                    color: "danger",
                    onClick: (item) => {
                        setModalDelete(true);
                        setId(item.id)
                    },
                })

            }
        }
        setActions(act);
        // eslint-disable-next-line
    }, [permisos, history]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (_create_type_payment.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_type_payment(CREATE_TYPE_PAYMENT));
            dispatch(get_all_type_payment(token));
            setIsLoading(false);
            setModal(false);
        } else if (_create_type_payment.status !== 0) {
            dispatch(clear_type_payment(CREATE_TYPE_PAYMENT));
            toast.error(_create_type_payment.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_type_payment, dispatch, history, token, id]);

    useEffect(() => {
        if (_update_type_payment.status === 200) {
            toast.success("Actualizado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_type_payment(UPDATE_TYPE_PAYMENT));
            dispatch(get_all_type_payment(token));
            setIsLoading(false);
            setModal(false);
            setId(0);
        } else if (_update_type_payment.status !== 0) {
            dispatch(clear_type_payment(UPDATE_TYPE_PAYMENT));
            toast.error(_update_type_payment.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_type_payment, dispatch, history, token, id]);

    useEffect(() => {
        if (_delete_type_payment.status === 200) {
            toast.success("Eliminado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_type_payment(DELETE_TYPE_PAYMENT));
            dispatch(get_all_type_payment(token));
            setModalDelete(false);
            setId(0);
            setIsLoading(false);
        } else if (_delete_type_payment.status !== 0) {
            dispatch(clear_type_payment(DELETE_TYPE_PAYMENT));
            toast.error(_delete_type_payment.message);
            setIsLoading(false);
        }
    }, [_delete_type_payment, history, token, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        const data = new FormData();
        data.append('nombre', form.nombre);
        data.append('pasarela', form.pasarela);
        data.append('imagen', form.imagen);
        if (id === 0) {
            dispatch(create_type_payment(token, data));
        } else {
            data.append('activo', form.activo);
            dispatch(update_type_payment(token, id, data));
        }
    };

    const handleDelete = () => {
        setIsLoading(true);
        dispatch(delete_type_payment(token, id));
    };

    const handleCheckedChange = (checked) => {
        setForm({
            ...form,
            pasarela: checked,
        });
    };

    const handleCheckedChangeActive = (checked) => {
        setForm({
            ...form,
            activo: checked,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        setImagePreview(url);
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Lista"
                            items={[{label: "Tipos de pagos", link: "/tipos-pagos"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_typepayment').length > 0 ?
                                        <Button type="button" text="Crear" theme="calendar" onClick={() => {
                                            setModal(true);
                                            setForm({...form, nombre: '', pasarela: false, imagen: ''});
                                            setId(0);
                                        }}/> : null
                                }
                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "nombre",
                                                label: "Nombre",
                                                type: "text",
                                            },
                                            {
                                                name: "fecha_creacion",
                                                label: "Fecha de creación",
                                                type: "text",
                                            },
                                            {
                                                name: "pasarela",
                                                label: "Pasarela",
                                                type: "boolean",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleSubmit}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">{id === 0 ? 'Crear ' : 'Editar '} tipo de pago</h5>
                        <div className="form-group">
                            <Input
                                label="Nombre"
                                name="nombre"
                                value={form.nombre}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <Input
                                name="imagen"
                                label="Imagen"
                                type="file"
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className="form-group text-center">
                            {form.imagen &&
                                <img
                                    src={
                                        imagePreview == null
                                            ? `${URLAPI}${form.imagen}`
                                            : imagePreview
                                    }
                                    alt="imagen"
                                    width={100}
                                />
                            }
                        </div>
                        <div className="form-group">
                            <Space size={14} direction='horizontal'>
                                <strong>Pasarela</strong>
                                <Switch
                                    checked={form.pasarela}
                                    checkedChildren="SI"
                                    unCheckedChildren="NO"
                                    onChange={handleCheckedChange}
                                />
                            </Space>
                        </div>
                        {id !== 0 && 
                            <div className="form-group">

                                <Space size={14} direction='horizontal'>
                                    <strong>Activo</strong>
                                    <Switch
                                        checked={form.activo}
                                        checkedChildren="SI"
                                        unCheckedChildren="NO"
                                        onChange={handleCheckedChangeActive}
                                    />
                                </Space>
                            </div>
                        }
                    </div>
                    <div className="card-footer text-center">
                        <button disabled={isLoading} onClick={() => {
                        }} className={`btn btn-calendar mb-1 button_div`}>Guardar
                        </button>
                        <button type="button" onClick={() => {
                            setModal(false);
                        }} className={`btn btn-outline-calendar button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main div-modal" show={modal_delete}>
                <div className="modal-content">
                    <div className="card-body text-center">
                        <h5>¿Está seguro de eliminar o inactivar el registro?</h5>
                    </div>
                    <div className="card-footer text-center">
                        <button type="button" className="btn btn-calendar mb-1 button_div" onClick={() => {
                            handleDelete()
                        }} disabled={isLoading}>
                            SI
                        </button>
                        <button type="button" className="btn btn-outline-calendar button_div" onClick={() => {
                            setModalDelete(false)
                        }}>
                            NO
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default TypePayment;
