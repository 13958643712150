import React from "react";

/**  @module Components/Button */

/**
 * Es una función que devuelve un elemento de botón con los elementos de texto, cambio, tema, tipo y
 * clases.
 *
 * @param {string} text - Texto del botón
 * @param {function} onChange - Función que se ejecuta al hacer clic en el botón
 * @param {string} theme - Tema del botón
 * @param {string} type - Tipo del botón
 * @param {string} classes - Clases del botón
 * @returns Un componente
 * @example
 * <Button
 *      text="Texto del botón"
 *      onChange={()=>{}}
 *      theme="success"
 *      type="button"
 *      classes="btn-lg"
 *    />
 */

const Button = ({ text, onChange, theme, type = "button", classes = "",...props }) => {
  return (
    <button
      type={type}
      onClick={onChange}
      className={`btn btn-${theme} ${classes}`}
      {...props}
    >
      {text}
    </button>
  );
};

export default Button;
