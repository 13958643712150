import React, {useEffect, useState} from "react";
import {URLAPI} from "../config/index";

export const add_datable_link = () => {
    document.head.innerHTML += `
    <link rel="stylesheet" type="text/css" href="/base/app-assets/vendors/css/tables/datatable/datatables.min.css">
    `;
    document.body.innerHTML += `
    <script src="/base/app-assets/vendors/js/tables/datatable/datatables.min.js"></script>
    
    `;
    //add script for datatable
    document.body.innerHTML += `
    <script>
    $(document).ready(function() {
        $('#table').DataTable();
    } );
    </script>
    `;
};
/** @module Components/Datatable */
/**
 * Una función que devuelve una tabla con paginación, búsqueda y acciones.
 * @param {array} dataTable - Array de datos para la tabla.
 * @param {ino} dataLimit - Límite de datos por página.
 * @param {int} pageLimit - Número de páginas.
 * @param {array} columns - Array de columnas de la tabla.
 * @param {array} actions - Array de acciones de la tabla.
 * @return {object} Un componente que representa una tabla.
 * @example
 * <DataTable
 *      dataTable={[
 *                  {id:1,name:"Juan",lastname:"Perez",age:30},
 *                  {id:2,name:"Juan",lastname:"Perez",age:30}
 *                ]}
 *      dataLimit={10}
 *      pageLimit={5}
 *      columns={[
 *                {label:"ID",name:"id"},
 *                {label:"Nombre",name:"name"},
 *                {label:"Apellido",name:"lastname"},
 *                {label:"Edad",name:"age"}
 *             ]}
 *      actions={[
 *                  {label:"Editar",name:"edit",icon:"bx bx-edit",color:"primary",onClick:()=>{}},
 *                  {label:"Eliminar",name:"delete",icon:"bx bx-trash",color:"danger",onClick:()=>{}}
 *              ]}
 *      />
 */
const DataTable = ({
                       dataTable = [{}],
                       dataLimit = 10,
                       pageLimit = 5,
                       columns = [{name: "", label: "", type: "", field_show: ""}],
                       actions = [
                           {
                               name: "edit",
                               label: "Edit",
                               icon: "edit",
                               color: "calendar",
                               onClick: () => {
                               },
                           },
                           {
                               name: "delete",
                               label: "Delete",
                               icon: "delete",
                               color: "danger",
                               onClick: () => {
                               },
                           },
                       ],
                   }) => {
    const [data_table, setDataTable] = useState([]);
    const [pages] = useState(Math.round(data_table.length / dataLimit));
    const [currentPage, setCurrentPage] = useState(1);

    function goToNextPage() {
        setCurrentPage((page) => page + 1);
    }

    function goToPreviousPage() {
        if (currentPage > 1) {
            setCurrentPage((page) => page - 1);
        } else {
            setCurrentPage(pages);
        }
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    }

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data_table.slice(startIndex, endIndex);
    };

    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    };

    useEffect(() => {
        setDataTable(dataTable);
        //converto to array
        if (typeof dataTable === "object") {
            setDataTable(Object.values(dataTable));
        }
    }, [dataTable]);

    const onSearch = (event) => {
        const search = event.target.value;
        if (search === "") {
            setDataTable(dataTable);
            return;
        }
        const filteredData = dataTable.filter((item) =>{

            return Object.values(item).some((value) => value?value.toString().toLowerCase().includes(search.toLowerCase()):"")

        }  );

        setDataTable(filteredData);
    };

    return (
        <>
            <nav aria-label="row position-relative ">
                {/* search input*/}
                <div className="col-md-12 mt-3 d-flex justify-content-end">
                    <div className="form-group ">
                        <input
                            type="text"
                            className="form-control"
                            id="search"
                            placeholder="Buscar..."
                            onChange={onSearch}
                        />
                    </div>
                </div>
            </nav>
            <table className="table table-striped table-bordered" id="table">
                <thead>
                <tr className="text-center">
                    {/*columns with sortable*/}
                    {columns.map((column) => (
                        <th key={column.name}>{column.label}</th>
                    ))}
                    {actions.length > 0 ? <th>Acciones</th> : null}
                </tr>
                </thead>
                <tbody>
                {getPaginatedData().map((item, index) => (
                    <tr key={index}>
                        {columns.map((column, index) => (
                            <td key={index}>
                                {column.type === "date" ? (
                                    new Date(item[column.name]).toLocaleDateString()
                                ) : column.type === "boolean" ? (
                                    item[column.name] ? (
                                        "Si"
                                    ) : (
                                        "No"
                                    )
                                ) : column.type === "avatar-dev" ? (
                                    <img
                                        src={item[column.name]
                                            .replace("/media/", "")
                                            .replace("https%253A", "https:/")
                                            .replace("http%3A", "http:/")}
                                        alt="avatar"
                                        width="50"
                                        height="50"
                                    />
                                ) : column.type === "avatar" ? (
                                    item[column.name] ?
                                        <img
                                            src={URLAPI + item[column.name]}
                                            alt="avatar"
                                            width="50"
                                            height="50"
                                        /> :
                                        <img
                                            src={window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
                                            alt="avatar"
                                            width="50"
                                            height="50"
                                        />
                                ) : column.type === "array" ? (
                                    //column.field_show recorrer el array y mostrar el valor
                                    item[column.name].map((it, index) => (
                                        <>
                                            <span
                                                key={index}
                                                className="
                                            badge badge-success badge-pill mt-1
                                          "
                                            >
                                              {it[column.field_show]}{" "}
                                            </span>
                                            <br/>
                                        </>
                                    ))
                                ) : column.type === "object" ? (
                                    //column.field_show recorrer y mostrar el valor

                                    <>
                                        {item[column.name][column.field_show]}{" "}
                                    </>
                                ) : (
                                    item[column.name]
                                )}
                            </td>
                        ))}
                        {actions.length > 0 ?
                            <td key={index} className="text-center">
                                {actions.map((action, index) => (
                                    // eslint-disable-next-line
                                    <a href="#" onClick={() => action.onClick(item)} className="mt-2 mr-1"
                                       data-popup="tooltip" title={action.label} data-trigger="manual">
                                        <i
                                            className={`badge-circle badge-circle-light-${action.color} ${action.icon} font-medium-1`}
                                        />
                                    </a>
                                ))}
                            </td> : null}
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="col-md-6">
                {getPaginatedData().length + " de " + data_table.length}
            </div>

            <ul className="pagination pagination-lg col-12 justify-content-end">
                {/* show page numbers */}
                <li
                    className={`page-item`}
                    onClick={goToPreviousPage}
                >
                    {/*eslint-disable-next-line*/}
                    <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                {/*eslint-disable-next-line*/}
                {getPaginationGroup().map((item, index) => (
                    <li
                        onClick={changePage}
                        className={`page-item  ${currentPage === item ? "active" : null} `}
                        key={index}
                    >
                        <span className="page-link">{item}</span>
                    </li>
                ))}
                <li
                    className={`page-item  ${currentPage === pages ? "active" : null} `}
                    onClick={goToNextPage}
                >
                    {/*eslint-disable-next-line*/}
                    <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </>
    );
};

export default DataTable;
